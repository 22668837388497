.youAreNotAloneBackgroundArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -54px;
  height: 640px;
  background-image: url("../../../images/custom-quest/left-how-it-works.svg"),
    url("../../../images/custom-quest/right-how-it-works.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top left, top right;
  /* z-index: -1; */
}

.youAreNotAloneBackgroundArea {
  width: 100%;
  background-size: 20% auto, 20% auto; /* Scale images for mobile */
  background-position: top left, topright;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}
/* .trybtn{
  z-index: 999 !important;
} */

/* 
.youAreNotAloneBackgroundArea::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../images/custom-quest/left-how-it-works.svg"),
                    url("../../../images/custom-quest/right-how-it-works.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top left, top right;
  background-size: 20% auto, 20% auto;
  z-index: -1;
} */


.youAreNotAloneArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 175px;
  margin-bottom: 175px;
  gap: 30px;
}
.topGraphicalAreaParagraph{
  width:560px !important
}
.topGraphicalAreaParagraphdesc{
  width: 530px !important;
  font-size: 25px;
  color: #6a6a6a;
  align-items: center;
  justify-content: center;
  text-align: center;
}