.AmbassadorsContainer {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.SomethingBigger {
  /* height: 405px; */
  background-color: #252153;
  padding: 104px 0px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.SomethingBigger > .heading {
  font-size: 32px;
  color: #fff;
  font-family: "Nohemi";
  margin: 0;
  padding: 0;
}

.SomethingBigger > .description {
  font-size: 24px;
  color: #959595;
  text-align: center;
  width: 480px;
  line-height: 35px;
  margin: 0;
  padding: 0;
}

.WaitListButton {
  display: none;
}


.EightSection {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profile-grid {
  display: grid;
  gap: 20px;
  /* width: 100%; */
  padding: 20px;
}

.profile-card-quest {
  perspective: 1000px; /* Adds perspective for 3D effect */
  /* width: 100%; */
  height: 400px;
}

.profile-card-inner {
  position: relative;
  /* width: 100%; */
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d; /* Ensures the child elements are in 3D space */
}

.profile-card-quest:hover .profile-card-inner {
  transform: rotateY(180deg); /* Flip the card on hover */
}

.profile-card-front,
.profile-card-back {
  position: absolute;
  height: 100%;
  backface-visibility: hidden; /* Hides the back side of the card */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 20px;
  max-width: 370px;
}

.profile-card-back {
  transform: rotateY(180deg); /* Initially hide the back side */
  background-color: #533884;
}

.team-image {
  border-radius: 0;
}

.howtojoin {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: 
    url("../../images/custom-quest/left-vector.svg"),
    url("../../images/custom-quest/right-vector.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: 
    top 30px left -300px,    /* Adjusted position for the first image */
    top 250px right -250px;  /* Adjusted position for the second image */
  z-index: 999;
  background-size: contain; /* Ensures the images are scaled properly */
}



.TaaraFirstIntro {
  gap: 20px;
  height: 280px;
  width: 110%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  font-size: 15px;
  font-family: "Nohemi";
  text-align: center;

 
  margin-bottom: 1.5rem;
}
.TaaraFirstIntroTopText {
  color: black;
  line-height: 24px;
}

@media (max-width: 768px) {
  .AmbassadorsContainer {
    padding: 0 16px;
  }
  .SomethingBigger {
    padding: 50px 0px;
    gap: 30px;
  }
  .SomethingBigger > .heading {
    font-size: 20px;
  }

  .SomethingBigger > .description {
    font-size: 12px;
    width: 280px;
    line-height: 20px;
  }
  .WaitListButton {
    background-color: #eb4176;
    color: white;
    width: 110px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    cursor: pointer;
    transition: transform 0.5s ease;
  }
}

@media (max-width: 1520px) {
  
  .profile-card-quest {
    perspective: 1000px; /* Adds perspective for 3D effect */
    width: 100%;
    height: 400px;
  }
}
@media (max-width: 1360px) {
  
  .profile-card-quest {
    perspective: 1000px; /* Adds perspective for 3D effect */
    width: 100%;
    height: 400px;
  }
}